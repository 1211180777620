<template>

    <SideMenu ref="sideNav" />
    <div class="pt-mobile-header">
        <div class="p-2 bg-light w-full text-center" @click="downloadMobileApp" role="button">
            <svg width="22" height="22" :class="$i18n.locale == 'ar' ? 'mr-3' : 'ml-3'" style="margin-bottom:-4px;" viewBox="0 0 21 21"><path fill="currentColor" d="M14.004 0H5.996A1.996 1.996 0 0 0 4 1.996v16.007C4 19.106 4.894 20 5.996 20h8.007A1.997 1.997 0 0 0 16 18.004V1.996A1.996 1.996 0 0 0 14.004 0M10 19c-.69 0-1.25-.447-1.25-1s.56-1 1.25-1s1.25.447 1.25 1s-.56 1-1.25 1m4-3H6V2h8z"/></svg>
            {{ $i18n.locale == 'ar' ? 'تحميل البرنامج' : 'Dowload mobile app' }}
        </div>
        <div class="container-fluid">
            <div class="pt-header-row">
                <!-- mobile menu toggle -->
                <div class="pt-mobile-parent-menu" @click="$refs.sideNav.openNav()">
                    <div class="pt-menu-toggle">
                        <svg width="24" height="24" viewBox="0 0 24 24">
                            <use
                                xlink:href="#icon-mobile-menu-toggle"
                            ></use>
                        </svg>
                    </div>
                </div>
                <!-- /mobile menu toggle -->
                <div class="pt-logo-container">
                    <!-- mobile logo -->
                    <div
                        class="pt-logo"
                        itemscope
                        itemtype="http://schema.org/Organization"
                    >
                        <a href="/" itemprop="url">
                            <img
                                :src="mobileLogoURL"
                                alt="Platinum Fit"
                                width="75"
                                height="75"
                            />
                        </a>
                    </div>
                    <!-- /mobile logo -->
                </div>
                <!-- cart -->
                <div class="pt-mobile-parent-search pt-parent-box"></div>
                <div class="pt-mobile-parent-cart pt-parent-box"></div>
                <!-- /cart -->
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'
import SideMenu from "./SideMenu.vue";

export default {
    components: {
        SideMenu,
    },
    methods: {
        downloadMobileApp(){
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // Check for Android
            if (/android/i.test(userAgent)) {
                window.open("https://play.google.com/store/apps/details?id=com.platinumfitkw.platinumfit&pcampaignid=web_share", '_blank');
            }

            // Check for iOS
            else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)  {
                window.open("https://apps.apple.com/eg/app/platinum-fit-%D8%A8%D9%84%D8%A7%D8%AA%D9%8A%D9%86%D9%88%D9%85-%D9%81%D8%AA/id1661739918", '_blank');
            }

            else {
                window.open("https://play.google.com/store/apps/details?id=com.platinumfitkw.platinumfit&pcampaignid=web_share", '_blank');
            }
        },
        clickedLogout() {
            this.$store.dispatch('logout');
        },
        
        redirectToAboutUs() {
            this.$router.push("/about-us")
        },

        redirectToProfile() {
            this.$router.push("/profile")
        },

        redirectToOrders() {
            this.$router.push("/orders")
        },

        redirectToAppointments() {
            this.$router.push("/appointments")
        },

        redirectToLogin() {
            this.$router.push("/login")
        },

        redirectToContact() {
            this.$router.push("/contact-us")
        },

        redirectToPackgesSection() {
            this.$router.push("/#packages")
        },

        redirectToBookingAppointment() {
            this.$router.push({ name: "DietitianAppointment" });
        }
    },
    computed: {
        ...mapState({
            isLoggedIn: (state) => state.config.isAuthenticated,
        }),
        mobileLogoURL() {
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }   
            return this.$root.getSiteContentDataForKey('header_logo_mobile')?.value
        }
    },
    watch: {
        isLoggedIn(newVal, OldVal) {
            if (newVal != OldVal) this.$forceUpdate()
        },
        siteContentResponse(data) {
            localStorage.setItem("siteContentData", JSON.stringify(data))
            this.$root.setSiteContentLoaded(true);
        }
    },
    mounted() {
        window.mobileHeaderComponent = this;
    },
};
</script>
