<template>
    <footer id="pt-footer" class="bg-darkblue mt-0 pt-3">
        <div class="pt-footer-col tt-color-scheme-01">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pt-3 d-none d-md-inline">
                        <div class="">
                            <h4 class="pt-footer-title pt-collapse-title new-color">
                                {{ $t("footer.info") }}
                            </h4>
                            <div class="pt-collapse-content ml-md-0">
                                <ul class="pt-list">
                                    <li><router-link class="text-white" to="/">{{ $t("footer.home") }}</router-link></li>
                                    <li><router-link class="text-white" to="/about-us">{{ $t("footer.aboutUs") }}</router-link></li>
                                    <li v-show="!isLoggedIn"><router-link class="text-white" to="/login">{{ $t("footer.login") }}</router-link></li>
                                    <li><router-link class="text-white" to="/contact-us">{{ $t("footer.contactUs") }}</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 pt-3">
                        <div class="">
                            <h4 class="pt-footer-title pt-collapse-title new-color">
                                {{ $t("footer.ourPolicies") }}
                            </h4>
                            <div class="pt-collapse-content ml-md-0">
                                <ul class="pt-list">
                                    <li><router-link class="text-white" to="/terms-and-conditions">{{ $t("footer.termsAndConditions") }}</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 pt-3 d-none d-md-inline">
                        <div class="">
                            <h4 class="pt-footer-title pt-collapse-title new-color">
                                {{ $t("footer.order") }}
                            </h4>
                            <div class="pt-collapse-content ml-md-0">
                                <ul class="pt-list">
                                    <li v-show="isLoggedIn"><router-link class="text-white" to="/profile">{{ $t("footer.myAccount") }}</router-link></li>
                                    <li><router-link class="text-white" to="/cart">{{ $t("footer.cart") }}</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 pt-3">
                        <div class="">
                            <h4 class="pt-footer-title pt-collapse-title new-color">
                                {{ $t("footer.contactUs") }}
                            </h4>
                            <div class="pt-collapse-content ml-md-0">
                                <address class="pt-address">
                                    <a class="text-white m-0" target="_blank" :href="addressMapLink" v-text="address"></a>
                                    <p class="text-white mt-1"><span v-text="timing"></span></p>
                                    <p class="text-white mt-1">
                                        <svg style="margin-bottom:-4px;" width="22" height="22" :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'" viewBox="0 0 18 18">
                                            <use xlink:href="#icon-services_support"></use>
                                        </svg>
                                        
                                        <a dir="ltr" :href="'tel:'+phoneNumber" target='_blank' class="text-white digit-text" v-text="phoneNumber"></a>
                                        <img src="@/assets/icons/whatsapp.png" :class="$i18n.locale == 'ar' ? 'mr-3 ml-2' : 'ml-3 mr-2'" width="22" height="22" /> 
                                        <a dir="ltr" :href="'https://wa.me/'+whatsappNumber" target='_blank' class="text-white digit-text" v-text="whatsappNumber"></a>
                                    </p>
                                    <p class="text-white mt-1">
                                        <svg style="margin-bottom:-6px;" width="22" height="22" viewBox="0 0 18 18" :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'">
                                            <use xlink:href="#icon-mail"></use>
                                        </svg>  
                                        <a dir="ltr" :href="'mailto:'+emailId" target='_blank' class="text-white digit-text" v-text="emailId"></a>
                                    </p>
                                    <p class="text-white mt-1">{{ $t("footer.followUs") }}:<a target="_blank" class="text-white" :href="instagramLink">
                                        <svg width="22" height="22" :class="$i18n.locale == 'ar' ? 'mr-3' : 'ml-3'" style="margin-bottom:-4px;" viewBox="0 0 18 18">
                                            <use xlink:href="#icon-social_icon_instagram"></use>
                                        </svg>
                                    </a></p>

                                    <p class="text-white mt-1">Android :<a target="_blank" class="text-white" :href="androidLink">
                                        <svg width="22" height="22" :class="$i18n.locale == 'ar' ? 'mr-3' : 'ml-3'" style="margin-bottom:-4px;" viewBox="0 0 18 18"><path fill="currentColor" fill-rule="evenodd" d="M12.735 1.828L13.6.254a.172.172 0 0 0-.3-.164l-.874 1.59a6 6 0 0 0-2.428-.508c-.869 0-1.69.181-2.424.506L6.698.09a.17.17 0 0 0-.232-.069a.17.17 0 0 0-.069.232l.866 1.572c-1.7.874-2.85 2.537-2.849 4.447l11.166-.001c0-1.91-1.146-3.57-2.845-4.444m-5.278 2.43a.467.467 0 1 1 .001-.934a.467.467 0 0 1 0 .935M15.542 6.7l.002 8.012c0 .481-.262.897-.648 1.127a1.3 1.3 0 0 1-.675.19l-.904.001v2.734a1.237 1.237 0 0 1-1.489 1.21a1.24 1.24 0 0 1-.99-1.209V16.03H9.163v2.735A1.237 1.237 0 0 1 7.925 20a1.24 1.24 0 0 1-1.238-1.235V16.03h-.901c-.521 0-.967-.3-1.182-.736a1.3 1.3 0 0 1-.141-.581l-.002-8.01zM2.74 6.47c.684 0 1.24.553 1.24 1.234v5.17q0 .127-.025.249a1.238 1.238 0 0 1-1.897.78a1.23 1.23 0 0 1-.557-1.03L1.5 7.704c0-.68.555-1.234 1.24-1.234m14.52-.001c.684 0 1.24.552 1.24 1.234v5.169c0 .683-.555 1.235-1.239 1.235c-.685 0-1.24-.552-1.24-1.235v-5.17c0-.682.554-1.235 1.238-1.234M12.54 3.325a.467.467 0 1 1 0 .933a.467.467 0 1 1 0-.933"/></svg>
                                    </a></p>

                                    <p class="text-white mt-1">IOS :<a target="_blank" class="text-white" :href="IOSLink">
                                        <svg width="22" height="22" :class="$i18n.locale == 'ar' ? 'mr-3' : 'ml-3'" style="margin-bottom:-4px;" viewBox="0 0 21 21"><path fill="currentColor" d="M17.05 20.28c-.98.95-2.05.8-3.08.35c-1.09-.46-2.09-.48-3.24 0c-1.44.62-2.2.44-3.06-.35C2.79 15.25 3.51 7.59 9.05 7.31c1.35.07 2.29.74 3.08.8c1.18-.24 2.31-.93 3.57-.84c1.51.12 2.65.72 3.4 1.8c-3.12 1.87-2.38 5.98.48 7.13c-.57 1.5-1.31 2.99-2.54 4.09zM12.03 7.25c-.15-2.23 1.66-4.07 3.74-4.25c.29 2.58-2.34 4.5-3.74 4.25"/></svg>
                                    </a></p>
                                </address>
                            </div>

                        </div>
                    </div>
                    <div class="col-12 col-md-2 d-none d-sm-flex">
                        <router-link class="mx-auto mt-3" to="/">
                            <img class="w-120" src="../assets/favicon/logo_white.png" alt="Platinum Fit" />
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import * as sessionManager from '../common_services/session-manager.js'

export default {
    computed: {
        isLoggedIn() {
            return sessionManager.isLoggedIn();
        },
        address() {            
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }   
            return this.$root.getSiteContentDataForKey('footer_address')?.value
        },
        addressMapLink() {
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }   
            return this.$root.getSiteContentDataForKey('footer_address_map')?.value
        },
        timing() {          
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }     
            return this.$root.getSiteContentDataForKey('footer_timing')?.value
        },
        phoneNumber() {
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }   
            return this.$root.getSiteContentDataForKey('footer_phone')?.value
        },
        whatsappNumber() { 
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }              
            return this.$root.getSiteContentDataForKey('footer_whatsapp')?.value
        },
        emailId() {
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }   
            return this.$root.getSiteContentDataForKey('footer_email')?.value
        },
        instagramLink() {         
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }      
            return this.$root.getSiteContentDataForKey('footer_instagram')?.value
        },
        androidLink(){
            return "https://play.google.com/store/apps/details?id=com.platinumfitkw.platinumfit&pcampaignid=web_share"
        },
        IOSLink(){
            return "https://apps.apple.com/eg/app/platinum-fit-%D8%A8%D9%84%D8%A7%D8%AA%D9%8A%D9%86%D9%88%D9%85-%D9%81%D8%AA/id1661739918"
        }
    },
    watch: {
        customerData(data) {
            this.customerData = data;
        }
    },
}
</script>